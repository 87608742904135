import { Size }                             from '@cs/core/generate/data-described/size.model';
import { getPropertyOf, hasPropertyOf, gv } from '@cs/core/utils';
import { isString }                         from '@cs/core/utils';
import { IValidationAnnotation }            from './validators/validation-annotations';
import { IpaSelectionOptions }              from './ipa-selection-options.model';
import { PropertyDependencyAnnotation }     from './property-dependency.annotation';
import { SelectionTargetEnum }              from './selection-target.enum';
import { SelectionTriggerEnum }             from './selection-trigger.enum';


export class PropertyAnnotation<T> implements IpaSelectionOptions {


	/**
	 * Indicates if the property is read-only
	 */
	readOnly: boolean;
	/**
	 * Indicates if the property is a key that can be set manual
	 */
	key: boolean;
	/**
	 * Indicates if the property is a PrimaryKey that can be set by the server
	 */
	generatedKey: boolean;
	/**
	 * Add this field to this group
	 */
	groupId: string;
	/**
	 * Format string
	 */
	format: string;
	template: string;
	cellTemplate: string;
	id: keyof T;
	lookup: string;
	type: string;
	optional: boolean;
	label: string;
	description: string;
	order: number;
	validators: IValidationAnnotation[];
	search: { searchEndPoint: boolean, hasSearchBar: boolean };
	visible: boolean;
	canFilter: boolean;
	canSort: boolean;
	/**
	 * Describes dependencies between properties, value is dependant on these properties
	 */
	dependencies: PropertyDependencyAnnotation[];

	// Selection Settings

	selectionValueTarget?: string;
	selectionTarget?: SelectionTargetEnum;
	selectionValueSource?: string;
	selectionTrigger?: SelectionTriggerEnum;
	selectionRoute?: string;
	selectionValue?: string | [];
	selectionMeta?: { [key: string]: any };
	size: Size;

	get hasDependencies() {
		return this.dependencies && this.dependencies.length > 0;
	}

	constructor(init: Partial<PropertyAnnotation<T>>) {
		this.id                   = getPropertyOf(init, 'id');
		this.label                = getPropertyOf(init, 'label', null);
		this.canFilter            = getPropertyOf(init, 'canFilter', false);
		this.canSort              = getPropertyOf(init, 'canSort', false);
		this.lookup               = getPropertyOf(init, 'lookup', null);
		this.visible              = getPropertyOf(init, 'visible', true);
		this.optional             = getPropertyOf(init, 'optional', true);
		this.description          = getPropertyOf(init, 'description', '');
		this.order                = getPropertyOf(init, 'order', null);
		this.type                 = getPropertyOf(init, 'type', null);
		this.cellTemplate         = getPropertyOf(init, 'cellTemplate', null);
		this.template             = getPropertyOf(init, 'template', null);
		this.key                  = getPropertyOf(init, 'key', false);
		this.generatedKey         = getPropertyOf(init, 'generatedKey', false);
		this.format               = getPropertyOf(init, 'format', null);
		this.readOnly             = getPropertyOf(init, 'readOnly', false);
		this.search               = getPropertyOf(init, 'search', {searchEndPoint: false, hasSearchBar: false});
		this.groupId              = getPropertyOf(init, 'groupId', null);
		this.validators           = getPropertyOf(init, 'validators', []);
		this.selectionTarget      = getPropertyOf(init, 'selectionTarget', null);
		this.selectionTrigger     = getPropertyOf(init, 'selectionTrigger', null);
		this.selectionValueSource = getPropertyOf(init, 'selectionValueSource', null);
		this.selectionValueTarget = getPropertyOf(init, 'selectionValueTarget', null);
		this.selectionMeta        = getPropertyOf(init, 'selectionMeta', null);
		this.size                 = hasPropertyOf(init, 'size')
																? new Size({
																						 size: gv(() => init.size.width.size, null),
																						 unit: gv(() => init.size.width.unit, null)
																					 },
																					 {
																						 size: gv(() => init.size.height.size, null),
																						 unit: gv(() => init.size.height.unit, null)
																					 })
																: Size.Empty;
		this.dependencies         = getPropertyOf(init, 'dependencies', [])
			.map(value => {
				if (isString(value))
					return {dependsOnId: value, type: 'Value'} as PropertyDependencyAnnotation;
				else
					return {dependsOnId: value.dependsOnId, type: getPropertyOf(value, 'type', 'Value')} as PropertyDependencyAnnotation;
			});
	}
}
