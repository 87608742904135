import { isArray, isNullOrUndefined, isObject } from './determine.utils';
import { ErrorMessage }                         from '@cs/core/error';
import { DataKey }                              from '../generate';

/**
 * Check if the property exists on a object
 * @param  obj Type of the object
 * @param  key Property we are looking for
 */
export function hasPropertyOf<T, K extends keyof T>(obj: T, key: K): boolean {
	return !isNullOrUndefined(obj[key]);
}

/**
 * get the property on a object
 * @param obj Type of the object
 * @param key Property we are looking for
 */
export function getPropertyOf<T, K extends keyof T>(obj: T, key: K, fallbackValue?: T[K]): T[K] {
	const result = (!isNullOrUndefined(obj) && obj.hasOwnProperty(key))
								 ? obj[key]
								 : fallbackValue;
	if (result === undefined && fallbackValue === undefined)
		throw new Error(ErrorMessage.PROPERTY_IS_NOT_FOUND(key.toString(), obj));
	else if (result === undefined && fallbackValue !== undefined)
		return fallbackValue;

	return result;

}

/**
 * A very forgiven wrapper function to get a nested property where the parents could be null or undefined
 * @param value the expression providing the value
 */
export function gv<T>(value: () => T, defaultValue: T = null): T | null {
	try {
		return value() ?? defaultValue;
	} catch (ex) {
		return defaultValue;
	}
}

export function isConstructor(obj) {
	return !!obj.prototype && !!obj.prototype.constructor.name;
}

/**
 * Use to create a empty object that is casted to the generic interface/class
 */
export function createDummyObject<T>(): T {
	return <T>{};
}

export function isEmpty(value: any) {
	return isNullOrUndefined(value) || value.length === 0 || !value.toString()
																																 .trim();
}

export function isEmptyObject(value: Object): boolean {
	return Object.keys(value).length === 0;
}

export function mapToObject<T extends string | number, D>(filterOptions: Map<T, D>,
																													filterExpression: (key: T,
																																						 value: D) => boolean = () => true): { [key: string]: D } {
	return Array.from(filterOptions.entries())
							.reduce((obj: any, [key, value]) => {
								if (filterExpression(key, value))
									obj[key] = value;
								return obj;
							}, {});
}

export function IsObject(obj: any) {
	const type = typeof obj;
	return type === 'function' || type === 'object' && !!obj;
}

export function isEqual(obj1, obj2) {
	const props1 = Object.getOwnPropertyNames(obj1);
	const props2 = Object.getOwnPropertyNames(obj2);
	if (props1.length != props2.length) {
		return false;
	}
	for (let i = 0; i < props1.length; i++) {
		const val1    = obj1[props1[i]];
		const val2    = obj2[props1[i]];
		let isObjects = isObject(val1) && isObject(val2);
		if (isObjects && !isEqual(val1, val2) || !isObjects && val1 !== val2) {
			return false;
		}
	}
	return true;
}

/**
 * Will reduce the nested object to one object. Where prop1.{name: "example"} will be prop1.name = "example"
 * @param queryObj the object that needs to be flatten
 * @param nesting the parent property name
 */
export function flattenObject(queryObj, nesting = ''): { [key: string]: any } {
	const queryParams = {};


	for (const key of Object.keys(queryObj)) {
		const val = queryObj[key];
		// Handle the nested, recursive case, where the value to encode is an object itself
		if (typeof val === 'object' && val !== null) {
			Object.assign(queryParams, flattenObject(val, nesting + `${key}.`));
		} else {
			// Handle base case, where the value to encode is simply a string.
			queryParams[nesting + (isArray(queryObj)
														 ? '~' + key
														 : key)] = val;
		}
	}
	return queryParams;
}

/**
 * Restores a object that has been @Link(flattenObject). Restores "prop1.name" = "test" to {prop1: {name:"test"}}
 * NOT SUPPORTED Array in Array
 * @param queryObj the flatten object
 */
export function restoreFlattenObject(queryObj, clearValues = false): { [key: string]: any } {
	const queryParams = {};
	for (const key of Object.keys(queryObj)) {
		const val = queryObj[key];
		// Handle the nested, recursive case, where the value to encode is an object itself
		if (key.indexOf('.') > -1) {

			let currentPropertyDepth = queryParams;
			// If the property has a dot treat it a nested object
			const properties         = key.split('.');
			const arrayIndex         = (properties.findIndex(value => value.indexOf('~') > -1) - 1);

			// Split the string by . and loop over the keys to restore an object
			for (let i = 0; i < properties.length; i++) {
				const currentKey           = properties[i];
				const isArrayPosition      = arrayIndex === i;
				const hasPropertyInitiated = currentPropertyDepth.hasOwnProperty(currentKey);

				if (currentKey.startsWith('~')) {
					// Remove the ~ prefix and parse as number to get the index
					const index                 = parseInt(currentKey.substr(1));
					currentPropertyDepth[index] = val;
					// Check if no property then init with empty array
				} else if (hasPropertyInitiated && isArrayPosition) {
					currentPropertyDepth = currentPropertyDepth[currentKey];
					// Check if no property then init with empty object
				} else if (!hasPropertyInitiated && isArrayPosition) {
					currentPropertyDepth[currentKey] = [];
					currentPropertyDepth             = currentPropertyDepth[currentKey];
					// Check if no property then init with empty object
				} else if (!hasPropertyInitiated && i < properties.length - 1) {
					currentPropertyDepth[currentKey] = {};
					currentPropertyDepth             = currentPropertyDepth[currentKey];
					// Check if property is initiated and set the currentPropertyDepth
				} else if (hasPropertyInitiated && i < properties.length - 1) {
					currentPropertyDepth = currentPropertyDepth[currentKey];
					// Check if array entry KEY.~1 = VALUE
				} else {
					currentPropertyDepth[currentKey] = clearValues
																						 ? null
																						 : val;
				}


			}
			// // Auto merge the same properties
			// if (!hasPropertyInQueryParams)
			// 	mergeDeep(queryParams, restoredObject);
		} else {
			// Handle base case, where the value to encode is simply a string.
			queryParams[key] = clearValues
												 ? null
												 : val;
		}
	}
	return queryParams;
}

/***
 * Performs a deep merge of `source` into `target`.
 * Mutates `target` only but not its objects and arrays.
 * @param target Object that needs to be merged with the source
 * @param source Object that updates the target
 */
export function mergeDeep(target, source) {
	const isObject = (obj) => obj && typeof obj === 'object';

	if (!isObject(target) || !isObject(source)) {
		return source;
	}

	Object.keys(source)
				.forEach(key => {
					const targetValue = target[key];
					const sourceValue = source[key];

					if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
						target[key] = targetValue.concat(sourceValue);
					} else if (isObject(targetValue) && isObject(sourceValue)) {
						target[key] = mergeDeep(Object.assign({}, targetValue), sourceValue);
					} else {
						target[key] = sourceValue;
					}
				});

	return target;
}

export function createObjectWithLowerCaseKeys<T>(obj): T {
	const lower  = Object.assign({}, obj);
	const output = {};
	for (const key of Object.getOwnPropertyNames(lower)) {
		output[key.toLowerCase()] = lower[key];
	}
	return <T>output;
}


export class FastZipObject {
	static zipIndexesByProps = new WeakMap<string[], Record<string, number>>();
	static LENGTH_THRESHOLD  = 11;

	static getZipIndexOfProp(headers: string[]) {
		let memoizedIndexes = FastZipObject.zipIndexesByProps.get(headers);
		if (memoizedIndexes) {
			return memoizedIndexes;
		}

		memoizedIndexes = headers.reduce((acc, header, i) => {
			acc[header] = i;
			return acc;
		}, {} as Record<string, number>);
		FastZipObject.zipIndexesByProps.set(headers, memoizedIndexes);
		return memoizedIndexes;
	}


	static createZipObject(props: string[], values: string[]) {
		if (props.length >= FastZipObject.LENGTH_THRESHOLD) {
			return FastZipObject.createProxyZipObject(props, values);
		}

		return FastZipObject.createSimpleZipObject(props, values);
	}

	static createSimpleZipObject(props: string[], values: string[]) {
		const obj: Record<string, unknown> = {};

		for (let i = 0; i < props.length; i++) {
			obj[props[i]] = values[i];
		}

		return obj;
	}

	static createProxyZipObject(props: string[], values: string[]) {
		const valuesCopy = [...values];
		const propsCopy  = [...props];

		return new Proxy(
			{},
			{
				get(target: any, prop: string) {
					return (
						target[prop] ||
						valuesCopy[FastZipObject.getZipIndexOfProp(propsCopy)[prop]]
					);
				},
				ownKeys: function () {
					return propsCopy;
				},
				getOwnPropertyDescriptor() {
					return {
						enumerable:   true,
						configurable: true,
						writable:     true
					};
				}
			}
		);
	}
}

export function containsKeys(keys: any, needle: any): boolean {
	const output = false;

	for (const key of Object.getOwnPropertyNames(needle)) {
		if (!(keys.hasOwnProperty(key) && keys[key] === needle[key]))
			return false;
	}

	return output;
}

export function convertKeysToFnv32a(keys: any, propertyOrder: string[] = null, sepToken: string = '#'): string {
	return convertKeysToFnv32aPath(keys, propertyOrder)
		.join(sepToken);
}

export function convertKeysToFnv32aPath(keys: any, propertyOrder: string[] = null): string[] {
	const output = [];
	// preserve key order: use Object.getOwnPropertyNames instead of Object.keys
	if (propertyOrder) {
		for (const key of propertyOrder) {
			const value = keys[key];
			output.push(`${key}=${value}`);
		}
	} else {
		for (const key of Object.getOwnPropertyNames(keys)) {
			const value = keys[key];
			output.push(`${key}=${value}`);
		}
	}
	return output.map(value => fnv32a(value)
		.toString());
}

export function fnv32a(str) {

	return fnv32aDebug(str);
	const FNV1_32A_INIT = 0x811c9dc5;
	let hval            = FNV1_32A_INIT;
	for (let i = 0; i < str.length; ++i) {
		// tslint:disable-next-line:no-bitwise
		hval ^= str.charCodeAt(i);
		// tslint:disable-next-line:no-bitwise
		hval += (hval << 1) + (hval << 4) + (hval << 7) + (hval << 8) + (hval << 24);
	}
	// tslint:disable-next-line:no-bitwise
	return hval >>> 0;
}

export function fnv32aDebug(str) {
	return str;
}

/**
 * Accessor Type for easy object
 */
export interface Dictionary<TValue = any> {
	[key: string]: TValue;
}

/**
 * Define Optional pick and some required properies
 */
export type OptionalExceptFor<T, TRequired extends keyof T = keyof T> = Partial<Pick<T, Exclude<keyof T, TRequired>>> &
	Required<Pick<T, TRequired>>;

/**
 * Create a dataKey object
 * @param rowData object that contains the keys
 * @param rowKeys the property names that should construct the @Link{DataKey}
 */
export function createKeysObject(rowData: any, rowKeys: string[]): DataKey {
	const output = {};

	if (rowData == null)
		return output;

	for (const key of rowKeys) {
		output[key] = rowData[key];
	}
	return output;
}
