import { AfterViewInit, Component, ElementRef, QueryList, ViewChildren } from '@angular/core';

import { isNullOrUndefined }            from '@cs/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { take }                         from 'rxjs/operators';
import { LookupControlWidget }          from '../models/lookup-control-widget.directive';

@UntilDestroy()
@Component({
						 selector: 'pm-select-widget',
						 template: `
						   <div class="widget form-group select-widget">
							   <!--<label [attr.for]="id" class="col-form-label"-->
							   <!--[class.read-only]="layout.readOnly"-->
							   <!--*ngIf="layout.showLabel">-->
							   <!--{{ propertyAnnotation.label }}-->
							   <!--</label>-->
							   <!-- DIRTY FIX FOR
								 'When select[multiple] is false then UI is not "multiple" but the ngModel return Array'
								 https://github.com/angular/angular/issues/12585
								 https://github.comangular/angular/blob/6.0.7/packages/forms/src
								 /directives/select_multiple_control_value_accessor.ts#L112-L138-->
							   <div *ngIf="isMultiSelect"
									[class.multi-select]="isMultiSelect">
								   <ng-container *ngIf="lookup?.values?.length <= 4">
									   <!-- Is used to store the checkbox selection -->
									   <select multiple [formControl]="control" style="display: none" [disabled]="readOnly"></select>
									   <label [attr.id]="id" *ngFor="let option of lookup?.values"
											  (mouseover)="selectionCheckboxChanged(option.key, true, $event)"
											  class="checkbox-container"
											  [class.checkbox-container--disabled]="readOnly">
										   {{option.value}}
										   <input [name]="name" class="form-control"
												  type="checkbox"
												  #checkbox
												  [attr.id]="id + option.key"
												  (change)="selectionCheckboxChanged(option.key, false, $event)"
										   > <span class="checkmark"></span>
									   </label>
								   </ng-container>
								   <ng-container *ngIf="lookup?.values?.length > 4">
									   <!--<select multiple [formControl]="control" style="display: none"></select>-->
									   <cs-combobox [multiple]="true"
													[keyProperty]="'value'"
													[valueProperty]="'key'"
													[formControl]="control"
													[disabled]="readOnly"
													[placeholder]="''"
													[filter]="lookup?.values?.length > 16"
													[disabled]="readOnly"
													[options]="lookup?.values">

									   </cs-combobox>
								   </ng-container>
							   </div>

							   <ng-container *ngIf="!isMultiSelect">
								   <ng-container *ngIf="lookup?.values?.length <= 4">
									   <label [attr.id]="id" *ngFor="let option of lookup?.values"
											  class="radio-button-container" [class.radio-button-container--disabled]="readOnly">
										   {{option.value}}
										   <input [name]="name" class="form-control"
												  type="radio"
												  #radio
												  [attr.id]="id + option.key"
												  (change)="selectionRadioButtonChanged(option.key)">
										   <span class="radio-button">
                        <span class="inner-radio-button"></span>
                      </span>
									   </label>
								   </ng-container>
								   <ng-container *ngIf="lookup?.values?.length > 4">
									   <cs-combobox [keyProperty]="'value'"
													[valueProperty]="'key'"
													[formControl]="control"
													[placeholder]="'-'"
													[isSmall]="true"
													[class.is-valid]="isValid"
													[class.is-invalid]="isInValid"
													[filter]="lookup?.values?.length > 16"
													[disabled]="readOnly"
													[options]="lookup?.values">

									   </cs-combobox>
								   </ng-container>
							   </ng-container>

							   <div class="pm-form-element-invalid-feedback" *ngIf="errorMessages?.length > 0 ">
								   <small class="{{error.type}}" *ngFor="let error of errorMessages">{{error.errorMessage}}</small>
							   </div>
						   </div>`
					 })
export class SelectWidgetComponent<T> extends LookupControlWidget<T> implements AfterViewInit {


	@ViewChildren('checkbox') checkBoxes: QueryList<ElementRef>;
	@ViewChildren('radio') radioButtons: QueryList<ElementRef>;

	selectionCheckboxChanged(key: string, hoverEvent, $event: MouseEvent) {
		if (hoverEvent && $event.button === 0 && $event.buttons === 0) {
			return;
		}

		if ((this.control.value instanceof Array)) {
			const found    = this.control.value.find(i => i === key);
			const newValue = isNullOrUndefined(found)
											 ? [...this.control.value, key]
											 : this.control.value.filter(f => f !== found);
			this.control.setValue(newValue);
			this.setCheckboxes(this.checkBoxes.toArray());
			this.control.markAsDirty({onlySelf: true});
		}
	}

	ngAfterViewInit() {
		super.ngAfterViewInit();

		this.checkBoxes.changes.pipe(take(1))
				.subscribe((s: QueryList<ElementRef>) => {
					const inputFields = s.toArray();
					this.setCheckboxes(inputFields);
				});

		this.radioButtons.changes.pipe(take(1))
				.subscribe((s: QueryList<ElementRef>) => {
					const inputFields = s.toArray();
					this.setRadioButtons(inputFields);
				});

		this.control.valueChanges.pipe(untilDestroyed(this))
				.subscribe((item) => {
					this.setRadioButtons(this.radioButtons.toArray());
					this.setCheckboxes(this.checkBoxes.toArray());
				});
	}

	private setCheckboxes(inputFields) {
		if ((this.control.value instanceof Array)) {

			for (const inputField of inputFields) {
				const field = this.control.value.find(val => inputField.nativeElement.id === this.id + val);

				inputField.nativeElement.checked = !isNullOrUndefined(field);

			}
		}
	}

	selectionRadioButtonChanged(key: string) {
		this.control.markAsDirty({onlySelf: false});
		this.control.setValue(key);

	}

	private setRadioButtons(inputFields: ElementRef[]) {
		for (const inputField of inputFields) {
			inputField.nativeElement.checked = inputField.nativeElement.id === this.id + this.control.value;
		}
	}

	clearData() {
		super.clearData();
		this.setCheckboxes(this.checkBoxes.toArray());
		this.setRadioButtons(this.radioButtons.toArray());
	}
}
