import { NgModule }              from '@angular/core';
import { CustomerRoutingModule } from '@cs/customer/customer-routing.module';

import { DefaultNavbarIconsFactory, SHELL_NAVBAR_ICONS_REPO } from '@cs/performance-manager';

export function CustomerNavbarIconsFactory() {
	const iconsLib = DefaultNavbarIconsFactory();
	return iconsLib;
}

/**
 * This bootstraps any angular customer specific code;
 */
@NgModule({
						declarations: [],
						imports:      [CustomerRoutingModule],
						providers:    [{provide: SHELL_NAVBAR_ICONS_REPO, useFactory: CustomerNavbarIconsFactory}],
						exports:      [CustomerRoutingModule]
					})
export class CustomerInitModule {}
