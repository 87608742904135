import { BrowserModule }                from '@angular/platform-browser';
import { Inject, Injectable, NgModule } from '@angular/core';
import { CustomerInitModule }           from '@cs/customer/customer-init.module';
import { CustomerRoutingModule }        from '@cs/customer/customer-routing.module';
import { AppRoutingModule }             from './app-routing.module';
import { AppComponent }                 from './app.component';
import {
	CsAvatarModule,
	CsDatepickerModule,
	CsToastManagerModule,
	CsPopoverModule,
	CsValidatorRegistry,
	DefaultCsValidatorRegistry,
	WidgetRegistry,
	DefaultWidgetRegistry, TOAST_CONFIG_TOKEN, defaultToastConfig, NguiMapModule,
	CsFilterAndCompareBarServicesModule,
	FormGeneratorLookupService, CsMaskModule, WizardComponentRegistry
}                                       from '@cs/components';
import {
	CsHttpModule,
	CsHttpRequestOptions, LoggerUtil,
	Result
}                                       from '@cs/core';
import {
	HttpClient,
	HttpClientModule,
	HttpErrorResponse
}                                       from '@angular/common/http';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
	TranslateLoader,
	TranslateModule,
	TranslateService
}                                  from '@ngx-translate/core';
import { HttpErrorGenericHandler } from '@cs/core';
import { CsDataGridModule }        from '@cs/components';
import {
	CsCultureModule, CsCultureProvider, CsRavenOptions, Environment,
	ERROR_LOGGING_PROVIDER, SentryLogger, CsCultureProviderDefault, AppMessageHubModule
}                                  from '@cs/common';
import {
	AuthenticationConfigService,
	AuthenticationModule,
	IsAuthenticated,
	ErrorHandlingModule,
	CsApplicationSettings,
	CsTranslationLoader,
	ErrorConfig,
	ERROR_CONFIG, LoginUserProfile
}                                  from '@cs/performance-manager';
import { pmAppSettings }           from '../environments/environment';
import { map }                     from 'rxjs/operators';

import { FormatRegistryUnilode }             from '../shared/config/format-registry';
import { ToastConfigUnilode }                from '../shared/config/toast-config';
import { dsnSentry }                         from '../environments/environment.base';
import { ComponentTranslationLoaderService } from '@cs/common';

import { Injector, APP_INITIALIZER }                                           from '@angular/core';
import { LOCATION_INITIALIZED }                                                from '@angular/common';
import { AppService, BASE_PATH, Configuration, ListViewService, UsersService } from '@cs/customer/api';
import { AppNavigationService }                                                from '@cs/common';
import { AppFormGeneratorLookupService }                                       from '../shared/services/app-lookup.service';

export function appInitializerFactory(translate: TranslateService, culture: CsCultureProvider, injector: Injector) {
	return () => new Promise<any>((resolve: any) => {
		const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
		locationInitialized.then(() => {
			const langToSet = culture.getCulture();

			translate.setDefaultLang(langToSet);
			translate.use(langToSet)
							 .subscribe((item) => {
								 LoggerUtil.debug(`Successfully initialized '${langToSet}' language.'`);
							 }, err => {
								 LoggerUtil.error(`Problem with '${langToSet}' language initialization.'`);
							 }, () => {
								 resolve(null);
							 });

			culture.onCultureChanged.subscribe(value => location.reload());
		});
	});
}

export class CsPmHttpErrorGenericHandler extends HttpErrorGenericHandler {
	handleError(error: HttpErrorResponse): boolean {
		return false;
	}

}

export function configurationFactory() {
	return new Configuration({withCredentials: true});
}

export function getErrorLogger() {
	const options              = new CsRavenOptions({
																										dns:         dsnSentry,
																										options:     {},
																										environment: pmAppSettings.environment,
																										version:     pmAppSettings.deploymentDetails.version
																									});
	options.enableErrorLogging = pmAppSettings.environment !== Environment.LOCAL;
	return new SentryLogger(options);
}

export function errorConfigFactory() {
	return new ErrorConfig({retryCount: 0});
}

export function toast_config_tokenFactory() {
	return defaultToastConfig;
}

@Injectable()
export class PmAuthenticationConfig extends AuthenticationConfigService {

	constructor(@Inject(UsersService) private usersService: UsersService,
							@Inject(AppService) private appService: AppService) {
		super();
	}

	isLoggedIn(errorHandler: CsHttpRequestOptions) {
		return this.usersService.usersAuthenticatedGet(errorHandler)
							 .pipe(
								 map(item => Result.success(new IsAuthenticated(item)))
							 );
	}


	logOut() {
		return this.usersService.usersLogoutPost()
							 .pipe(
								 map(item => Result.success(true))
							 );
	}

	keepAlivePing(errorHandler: CsHttpRequestOptions) {
		return this.appService.appKeepAliveGet(errorHandler)
							 .pipe(
								 map(item => Result.success(true))
							 );
	}

	getUserProfile() {
		return this.usersService.usersProfilePersonalGet()
							 .pipe(map(value => Result.success(new LoginUserProfile(value as unknown as LoginUserProfile))));
	}

	autoLogin(token: string, errorHandler?: CsHttpRequestOptions) {
		return this.usersService.usersAutoLoginPost(token, errorHandler)
							 .pipe(map(value => Result.success(value as LoginUserProfile)));
	}

	authLogin(method: string, params, errorHandler?: CsHttpRequestOptions) {
		return this.usersService.usersLoginConfirmationPost(method, params.secret,
																												params.state, params.code, errorHandler)
							 .pipe(map(value => Result.success(value as LoginUserProfile)));
	}
}


export function applicationWideTranslationLoader(client: HttpClient,
																								 pmAppSettings: CsApplicationSettings,
																								 componentLoader: ComponentTranslationLoaderService) {
	return new CsTranslationLoader(`${pmAppSettings.translationLocation}/app`, client, componentLoader,
																 pmAppSettings.deploymentDetails.buildDate);
}

export function applicationCultureProvideLoader(pmAppSettings: CsApplicationSettings) {
	const cultureSettings = new CsCultureProviderDefault();
	cultureSettings.setTranslationLocation(pmAppSettings.translationLocation);
	cultureSettings.setAvailableLanguages(['en', 'nl']);
	return cultureSettings;
}

@NgModule({
						declarations: [
							AppComponent
						],
						imports:      [
							BrowserModule,
							BrowserAnimationsModule,
							HttpClientModule,
							ErrorHandlingModule.forRoot(),
							TranslateModule.forRoot({
																				loader: {
																					provide:    TranslateLoader,
																					useFactory: applicationWideTranslationLoader,
																					deps:       [
																						HttpClient, CsApplicationSettings, ComponentTranslationLoaderService,
																						ComponentTranslationLoaderService
																					]
																				}
																			}),

							AuthenticationModule.forRoot(PmAuthenticationConfig, pmAppSettings),
							CsAvatarModule,
							CsDatepickerModule.forRoot(),
							CsDataGridModule.forRoot(),
							CsCultureModule.forRoot(FormatRegistryUnilode),
							CsMaskModule.forRoot(),
							CsToastManagerModule.forRoot(ToastConfigUnilode),
							CsPopoverModule,
							CsFilterAndCompareBarServicesModule,
							CsHttpModule.forRoot(CsPmHttpErrorGenericHandler),
							NguiMapModule.forRoot({apiKey: pmAppSettings.googleMapsApiKey, apiUrl: 'https://maps.google.com/maps/api/js'}),
							AppMessageHubModule.forRoot(),
							CustomerInitModule,
							...pmAppSettings.injectModules
						],
						providers:    [
							{provide: CsCultureProvider, useFactory: applicationCultureProvideLoader, deps: [CsApplicationSettings]},
							{
								provide:    APP_INITIALIZER,
								useFactory: appInitializerFactory,
								deps:       [TranslateService, CsCultureProvider, Injector],
								multi:      true
							},
							{provide: BASE_PATH, useValue: pmAppSettings.apiUrl},
							{provide: Configuration, useFactory: configurationFactory},
							{provide: ERROR_LOGGING_PROVIDER, useFactory: getErrorLogger},
							{provide: ERROR_CONFIG, useFactory: errorConfigFactory},
							{provide: TOAST_CONFIG_TOKEN, useFactory: toast_config_tokenFactory},
							{provide: CsValidatorRegistry, useClass: DefaultCsValidatorRegistry},
							{provide: WidgetRegistry, useClass: DefaultWidgetRegistry},
							{provide: FormGeneratorLookupService, useClass: AppFormGeneratorLookupService, deps: [ListViewService]},
							AppNavigationService,
							UsersService,
							AppService,
							ListViewService,
							WizardComponentRegistry
						],
						bootstrap:    [AppComponent]
					})
export class AppModule {

}
