<div class="data-entry-container page-container"
	 [class.show-chart]="(showChart$ | async) && !(chartPanelAgent?.isChartCollapsed$ | async)">
	<div *ngIf="dataEntryGrid?.data?.config?.layout?.pageDescription"
		 class="page-description--container overflow-page-container mb-0">
		<div class="cs-panel flex flex-align-items-center justify-content-start">
			<div class="mr-2">
				<i class="{{iconType}}"></i>
			</div>
			<div>{{dataEntryGrid?.data?.config?.layout?.pageDescription}}</div>

		</div>
	</div>
	<div class="full-width dynamic-buttons-component"
		 [@loadingPanelState]="isLoadingDataGrids$ | async">
		<pmc-dynamic-button-bar [title]="dataEntryGrid?.layout?.pageTitle"
								[currentPageComponent]="this"
								[dynamicButtons]="dataEntryGrid?.dynamicButtons"
		>
		</pmc-dynamic-button-bar>
	</div>
	<div class="full-width chart-panel-container"
		 *ngIf="showChart$ | async">
		<cs-chart-panel #chartPanel
						(tabSwitched)="statisticsChanged($event)"
						[isStatsLoading]="isStatisticsPanelLoading$ | async"
						[isChartLoading]="isChartPanelLoading$ | async"
						[collapsed]="chartPanelAgent?.isChartCollapsed$ | async"
						[eventsPanelVisible]="chartPanelAgent?.eventPanelActive$ | async"
						[chartPanelData]="chartPanelAgent?.chartData"
						[options]="chartPanelAgent?.chartOptions"
						[activeTab]="chartPanelAgent?.activeTabStatistics"
						[statsData]="chartPanelAgent?.statsData">

		</cs-chart-panel>

		<!--		<cs-dashboard [data]="dataDashboard"-->
		<!--					  #dashboard-->
		<!--					  (dashboardEntityClicked)="onDashboardEntryClick($event)"-->
		<!--					  (downloadButtonClicked)="onDownloadButtonClicked($event)"-->
		<!--					  (panelOptionSelected)="onPanelOptionSelected($event)"-->
		<!--					  (newDashboardRendered)="newDashboardRendered($event)"-->
		<!--					  (showDetailsButtonClicked)="onShowDetailsButtonClick($event)"-->
		<!--					  (applicationTriggerRequested)="onApplicationTriggerRequested($event)"-->
		<!--					  (notifyChangesToServer)="onNotifyChangesToServer($event)"-->
		<!--					  [@isCollapsed]="chartPanelAgent?.isChartCollapsed$ | async">-->
		<!--		</cs-dashboard>-->

		<div class="full-width details-panel-container" #detailPanel *ngIf="isChangesPanelVisible$ | async">
			<div class="detail-panel-background">
				<pmc-pm-detail-panel (closeButtonClicked)="hideChangesPanel()">
					<pm-audit-trail-changes #auditChanges></pm-audit-trail-changes>
				</pmc-pm-detail-panel>
			</div>
		</div>
	</div>

	<div class="full-width data-grid-sub-filter-container"
		 *ngIf="subFilterDataSource">
		<cs-filter-and-compare-bar [mDataSource]="subFilterDataSource"
								   (selectionChanged)="subFilterBarSelectionChanged($event)"
								   [SyncUrlParameters]="false"
								   [useLocalOnPage]="true"
								   [invertStyling]="false"
								   [renderStubs]="false"
								   #subfilterBar></cs-filter-and-compare-bar>
	</div>

	<div class="data-grid-background-container"
		 [@loadingPanelState]="isLoadingDataGrids$ | async">
		<div class="overflow-page-container">

			<cs-data-grid #data
						  scroll-page
						  [scrollMin]="100"
						  [renderAsForm]="renderAsForm"
						  [useElementHeight]="false"
						  [isLoadingNewContent]="isLoadingNewContent$ | async"
						  (onScroll)="dataEntryAgent.loadNewData($event, this)"
						  [enableScrollDetection]="enableScrollDetection$ | async"
						  [sheets]="dataEntryGrid?.sheets"
						  [options]="dataEntryGrid?.options"
						  (onSheetActionClicked)="dataEntryAgent.sheetActionButtonClicked($event, this)"
						  (requestingCellActionOnClick)="dataEntryAgent.cellClicked($event, this)"
						  (onRowButtonClicked)="dataEntryAgent.rowButtonClicked($event, this)"
						  (onCellsEdited)="dataEntryAgent.cellsEdited($event, this)"
						  (onNavigationRequested)="dataEntryAgent.onNavigationRequested($event, this)"
			>
			</cs-data-grid>
		</div>
	</div>
</div>
